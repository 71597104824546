import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { transformImages } from 'helpers/transformImages';

export const purpleCards = [
  {
    id: 0,
    desc: 'Advanced filtering will help you remain focused on the crucial recordings only.',
    title: 'Filters',
  },
  {
    id: 1,
    desc: 'Save filter presets and segment your visitor’s base so you can quickly start where you left off.',
    title: 'Segments',
  },
  {
    id: 2,
    desc: 'Set up daily or weekly notifications and get notified about changes in segments you created.',
    title: 'Segment notifications',
  },
  {
    id: 3,
    desc: 'Cut off unimportant traffic from being tracked. Reduce the clutter in your sessions list.',
    title: 'Recording rules',
  },
];

export const blueCards = [
  {
    id: 0,
    desc: 'Watch recurring sessions of a single user and see how changes you made impact their buying decisions.',
    title: 'User identification',
  },
  {
    id: 1,
    desc: 'Add more context to each session and give your support team an extra piece of data to provide better customer experience.',
    title: 'Custom properties',
  },
  {
    id: 2,
    desc: 'Extend your filter list with industry-specific events to better suit your needs. Track actions that bring your visitors closer to making a buying decision.',
    title: 'Custom events',
  },
];

export const yellowCards = [
  {
    id: 0,
    title: 'Session replays',
    desc: 'See what users do on your website and find bottlenecks in your user’s journey.',
  },
  {
    id: 1,
    title: 'Funnels',
    desc: `Analyze paths that can lead to conversion and get to know which one you should improve.`,
  },
  {
    id: 2,
    title: 'Click & Heat Maps',
    desc: `Find places on your website where a visit will most likely convert into a sale. `,
  },
  {
    id: 3,
    title: 'Dev Tools',
    desc: `Detect JavaScript errors on the spot and debug your web application with ease.`,
  },
];

export const useFeatureData = () => {
  const {
    session,
    heatmap,
    devtools,
    funnels,
    allFile: { edges },
  } = useStaticQuery(graphql`
    query {
      session: file(relativePath: { eq: "ecommerce/session.png" }) {
        childImageSharp {
          fluid(quality: 95, maxWidth: 1600) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      heatmap: file(relativePath: { eq: "ecommerce/heatmap.png" }) {
        childImageSharp {
          fluid(quality: 95, maxWidth: 1600) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      devtools: file(relativePath: { eq: "ecommerce/devtools.png" }) {
        childImageSharp {
          fluid(quality: 95, maxWidth: 1600) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      funnels: file(relativePath: { eq: "ecommerce/funnels.png" }) {
        childImageSharp {
          fluid(quality: 95, maxWidth: 1600) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      allFile(
        filter: {
          extension: { regex: "/(jpg)|(jpeg)|(png)/" }
          relativeDirectory: { eq: "featurePhotos" }
        }
      ) {
        edges {
          node {
            name
            childImageSharp {
              fluid(quality: 95, maxWidth: 1600) {
                ...GatsbyImageSharpFluid_tracedSVG
              }
            }
          }
        }
      }
    }
  `);

  const pics = transformImages(edges);

  return {
    yellow: {
      cards: yellowCards,
      images: [session, funnels, heatmap, devtools],
    },
    blue: {
      cards: blueCards,
      images: [pics.blue1, pics.blue2, pics.blue3],
    },
    purple: {
      cards: purpleCards,
      images: [pics.purple1, pics.purple2, pics.purple3, pics.purple4],
    },
    featureCards: [
      {
        title: 'Engagement score',
        desc: (
          <>
            <p>
              Find the most engaging sessions without having to watch them all. Our Engagement score
              allows you to focus on recordings with the most (or least) user activity. The rating
              depends on many factors, including the number of pages visited by your users and the
              number of clicks during the session. The Engagement score will{' '}
              <strong>show you the most important sessions automatically</strong>.
            </p>
          </>
        ),
        image: pics.engagement.node.childImageSharp.fluid,
      },
      {
        title: 'User journey',
        desc: (
          <>
            <p>
              A user journey is a path your visitors take when exploring your website. In
              LiveSession, you can easily watch and analyze it. It will help you{' '}
              <strong>improve the sales funnel</strong> in your store or make adjustments to your
              website’s navigation. You can also use it to track events that led to conversions and
              see how they contributed to achieving your goals.
            </p>
          </>
        ),
        image: pics.userjourney.node.childImageSharp.fluid,
      },
      {
        title: 'Rage clicks & error clicks',
        desc: (
          <>
            <p>
              Rage clicks and Error clicks{' '}
              <strong>automatically spot pain points and errors</strong> on your website. Rage click
              happens when your visitor keeps clicking on an UI element repeatedly and may indicate
              your visitors frustration. Error clicks are reported when a JavaScript error occurs.
              LiveSession marks them automatically to make it easier for you to{' '}
              <strong>find the most urgent issues</strong>.
            </p>
          </>
        ),
        image: pics.errors.node.childImageSharp.fluid,
      },
      {
        title: 'Inspect mode',
        desc: (
          <>
            <p>
              While watching a recording, you can{' '}
              <strong>select any particular element of your website</strong> and see how it’s used.
              This allows you to browse sessions in which your users interact with this element.
              Depending on your needs, you can search by CSS selectors or by text. With Inspect
              mode, you can <strong>interpret A/B tests</strong> results or understand how users
              interact with your website.
            </p>
          </>
        ),
        image: pics.inspect.node.childImageSharp.fluid,
      },
    ],
    personalData: {
      mainImage: pics.personaldata.node.childImageSharp.fluid,
    },
  };
};
