import researchers from 'img/index/cardsSmartWays/researchers.png';
import marketers from 'img/index/cardsSmartWays/marketers.png';
import developers from 'img/index/cardsSmartWays/developers.png';

export const cards = [
  {
    id: 0,
    title: 'Session replays',
    desc: 'Advanced filtering will help you remain focused on the crucial recordings only.',
  },
  {
    id: 1,
    title: 'Funnels',
    desc: `Organizing visitors' paths into funnels, you will be able to analyze your customers' journeys to purchase.`,
  },
  {
    id: 2,
    title: 'Click & Heat Maps',
    desc: `Visualization of your website’s hottest sections will enable you to increase the number of actions.`,
  },
  {
    id: 3,
    title: 'Dev Tools',
    desc: `Console logs will let you get rid of errors that lead to user’s irritation and, in the end - lower your conversion rates.`,
  },
];

export const cardsSmartWays = [
  {
    title: 'UX Researchers',
    desc: 'Use session recordings to get started with usability testing. See how exactly the users interact with your website. It’s just like you’re sitting next to them.',
    link: '/customers/rocketlink/',
    img: researchers,
    bgColor: '#f8f8f8',
    linkText: 'Get to know your users',
  },
  {
    title: 'Marketers',
    desc: 'Analyze funnels with qualitative data. Filter sessions based on different variables, such as UTM tags. Track specific events according to your needs.',
    link: '/customers/databox/',
    img: marketers,
    bgColor: '#f8f8f8',
    linkText: 'Boost your marketing',
  },
  {
    title: 'Developers',
    desc: 'Find errors faster thanks to DevTools. Detect JavaScript errors with error clicks and points of frustration with rage clicks.',
    link: '/customers/talentlyft/',
    img: developers,
    bgColor: '#f8f8f8',
    linkText: 'Remove bugs faster',
  },
];
