import styled from 'styled-components';
import { media } from 'theme/breakpoints';
import { StyledParagraph } from 'components/ui/Paragraph';

export const Section = styled.section`
  & h2 {
    font-weight: 800;
    font-size: 36px;
    margin-bottom: 24px;
  }
  & ${StyledParagraph} {
    margin-bottom: 40px;
    ${media.mobile} {
      margin-bottom: 32px;
      font-size: 16px;
      line-height: 24px;
    }
  }
  ${media.mobile} {
    padding: 5rem 0 48px;
  }
`;
