import React from 'react';
import PropTypes from 'prop-types';
import Button from 'components/ui/button';
import Paragraph from 'components/ui/Paragraph';
import * as Styled from './styled';

const Intro = ({ buttonText }) => {
  return (
    <div className="container">
      <Styled.Section className="text-center">
        <div>
          <h2>Start using LiveSession for free</h2>
          <Paragraph size="l">
            Test all LiveSession features for 14 days, no credit card required.
          </Paragraph>
          <Button signUp secondary={!buttonText} center sourceID="middle">
            {buttonText || 'Sign up free'}
          </Button>
        </div>
      </Styled.Section>
    </div>
  );
};

export default Intro;

Intro.propTypes = {
  buttonText: PropTypes.string,
};

Intro.defaultProps = {
  buttonText: undefined,
};
