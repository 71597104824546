import shopify from 'img/companyLogo/icons/shopify.svg';
import wix from 'img/companyLogo/icons/wix.svg';
import bigcommerce from 'img/companyLogo/icons/bigcommerce.svg';
import prestashop from 'img/companyLogo/icons/prestashop.svg';
import magento from 'img/companyLogo/icons/magento.svg';
import squarespace from 'img/companyLogo/icons/squarespace.svg';
import woocommerce from 'img/companyLogo/icons/woocommerce.svg';
import ecwid from 'img/companyLogo/icons/ecwid.svg';
import opencart from 'img/companyLogo/icons/opencart.svg';

export const platforms = [
  {
    img: prestashop,
    alt: 'PrestaShop',
  },
  {
    img: magento,
    alt: 'Magento',
  },
  {
    img: shopify,
    alt: 'Shopify',
    link: '/integrations/shopify/',
  },

  {
    img: bigcommerce,
    alt: 'BigCommerce',
    link: '/integrations/bigcommerce/',
  },
  {
    img: squarespace,
    alt: 'Squarespace',
  },
  {
    img: woocommerce,
    alt: 'WooCommerce',
  },
  {
    img: wix,
    alt: 'Wix',
    link: '/integrations/wix/',
  },
  {
    img: ecwid,
    alt: 'Ecwid',
  },
  {
    img: opencart,
    alt: 'Open Cart',
  },
];
